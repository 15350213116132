import translationsBlueprint from 'locales/models/pl.json';
import { Moment } from 'moment';
import moment from 'moment-timezone';

import ApiBundle from 'types/api/Bundle';
import { Genders } from 'types/genders';
import { Locales } from 'types/locale';
import { Option } from 'types/options';

import BundleComparison from 'models/BundleComparison';
import BundleDescription from 'models/BundleDescription';
import BundleFaq from 'models/BundleFaq';
import BundleInfo from 'models/BundleInfo';
import BundlePricing from 'models/BundlePricing';
import BundlePromo from 'models/BundlePromo';
import BundleStage from 'models/BundleStage';
import { Types } from 'models/CartItem';
import { Timezone } from 'models/Market';
import Model from 'models/Model';
import ProductCategory from 'models/ProductCategory';
import ProductLocale from 'models/ProductLocale';

import { getFormattedDate } from 'utils/date';
import { select as selectLocale } from 'utils/locale';
import { displayPrice } from 'utils/math';

export interface PricingMatrixDataPricing {
    quantityFrom: number;
    quantityTo: number;
    price: number;
    priceDisplay: string;
}

const translationsModels: typeof translationsBlueprint.comp = selectLocale({
    [Locales.En]: require('locales/models/en.json'),
    [Locales.Pl]: require('locales/models/pl.json'),
});

export default class Bundle implements Model {
    origin: ApiBundle;
    id: string;
    modelType: string;
    createdAt: Moment;
    createdAtDisplay: string;
    name: string;
    gender: Genders;
    genderOption: Option<Genders | string>;
    category: ProductCategory;
    categoryOption: Option<ProductCategory>;
    enabled: boolean;
    eventLimit: number;
    eventLimitDisplay: string;
    minProductSelection: number;
    packageBonusQuantity: number;
    packageQuantityTotal: number;
    packageQuantity: number;
    validityDurationMonths: number;
    showLowestPrice?: boolean;
    vatLevel?: number;
    locale: ProductLocale;
    pricing: BundlePricing;
    type: Types;
    isMostBought: boolean;
    bundleInfos: BundleInfo[];
    color: string;
    order: number;

    descriptions: BundleDescription[];
    stages?: BundleStage[];
    faqs: BundleFaq[];
    comparisons: BundleComparison[];

    ongoingPromos: BundlePromo[];
    hasOngoingPromos: boolean;
    ongoingPromo: BundlePromo;
    promoPercent: number;

    oneItemPrice: number;
    oneItemPriceDisplay: string;

    promoValidTo?: Moment;
    promoValidToDisplay?: string;
    promoRecommended: boolean;
    isSpecialOffer: boolean;

    constructor(data: ApiBundle) {
        this.origin = data;
        this.id = data.id;
        this.modelType = data.modelType;
        this.type = Types.Bundle;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).tz(Timezone);
        this.createdAtDisplay = this.createdAt && getFormattedDate(this.createdAt);
        this.name = data.name;
        this.gender = data.gender;
        this.genderOption = this.getGenderOption(this.gender);
        this.category = data.category && new ProductCategory(data.category);
        this.categoryOption = this.category && this.category?.getOption();
        this.enabled = Boolean(data.enabled);
        this.eventLimit = data.eventLimit;
        this.eventLimitDisplay = this.getDurationDisplay(this.eventLimit);
        this.minProductSelection = data.minProductSelection;
        this.packageBonusQuantity = data.packageBonusQuantity;
        this.packageQuantityTotal = data.packageQuantityTotal;
        this.packageQuantity = data.packageQuantityTotal - data.packageBonusQuantity;
        this.validityDurationMonths = data.validityDurationMonths;
        this.showLowestPrice = data.showLowestPrice;
        this.vatLevel = data.vatLevel;
        this.isMostBought = Boolean(data.isMostBought);
        this.locale = data.locale && new ProductLocale(data.locale) || null;
        this.pricing = data.pricing && new BundlePricing(data.pricing) || null;
        this.color = data.color;
        this.order = data.order;

        this.bundleInfos = Array.isArray(data.bundleInfos)
            ? data.bundleInfos.map(bundleInfo => new BundleInfo(bundleInfo))
            : [];

        this.descriptions = Array.isArray(data.descriptions)
            ? data.descriptions
                .map(element => new BundleDescription(element))
                .sort((elemA, elemB) => elemA.order - elemB.order)
            : [];
        this.stages = Array.isArray(data.stages)
            ? data.stages
                .map(stage => new BundleStage(stage))
                .sort((elemA, elemB) => elemA.order - elemB.order)
            : [];
        this.faqs = Array.isArray(data.faqs)
            ? data.faqs
                .map(element => new BundleFaq(element))
                .sort((elemA, elemB) => elemA.order - elemB.order)
            : [];
        this.comparisons = Array.isArray(data.comparisons)
            ? data.comparisons
                .map(element => new BundleComparison(element))
                .sort((elemA, elemB) => elemA.order - elemB.order)
            : [];

        this.ongoingPromos = Array.isArray(data.ongoingPromos)
            ? data.ongoingPromos.map(ongoingPromo => new BundlePromo(ongoingPromo))
            : [];
        this.hasOngoingPromos = this.ongoingPromos.length > 0;
        this.ongoingPromo = this.getOngoningPromo();
        this.promoPercent = this.ongoingPromo && this.getPromoPercent(this.pricing?.price, this.ongoingPromo?.pricing?.price);

        this.oneItemPrice = this.getOneItemPrice();
        this.oneItemPriceDisplay = displayPrice(Math.round(this.oneItemPrice), this.pricing?.currency);

        this.promoValidTo = data.promoValidTo && moment.utc(data.promoValidTo).tz(Timezone);
        this.promoValidToDisplay = this.promoValidTo && getFormattedDate(this.promoValidTo, 'date');
        this.promoRecommended = data.promoRecommended;
        this.isSpecialOffer = data.isSpecial;
    }

    getOption = (): Option<Bundle> => {
        return {
            value: this,
            label: this.name,
        };
    };

    getGenderOption = (value: Genders): Option<Genders | string> => {
        return genderOptions.find(option => option.value === value);
    };

    getDurationDisplay = (duration: number): string => {
        return translationsModels?.bundle?.druration?.label.replace('{{duration}}', duration?.toString());
    };

    getOngoningPromo = (): BundlePromo => {
        if (!this.hasOngoingPromos) return null;

        return this.ongoingPromos[0];
    };


    getPromoPercent = (price: number, promoPrice: number): number => {
        if (!promoPrice) return 0;

        const difference = price - promoPrice;
        const promoPercent = (difference / price) * 100;

        return Math.round(promoPercent);
    };

    getOneItemPrice = (): number => {
        if (this.ongoingPromo) return (this.ongoingPromo?.pricing?.price || 0) / this.packageQuantityTotal;

        return (this.pricing?.price || 0) / this.packageQuantityTotal;
    };
}

export const genderOptions = [{
    value: Genders.Male,
    label: translationsModels?.bundle?.genders?.male,
}, {
    value: Genders.Female,
    label: translationsModels?.bundle?.genders?.female,
}];
