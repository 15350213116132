import ApiLocationInfo from 'types/api/LocationInfo';

import Model from 'models/Model';

export enum SectionTypes {
    Indications = 'indications',
    Contraindications = 'contraindications',
    Benefits = 'benefits',
}
export default class LocationInfo implements Model {
    id: string;
    section: SectionTypes;
    description: string;
    order: number;

    constructor(data: ApiLocationInfo) {
        this.id = data.id;
        this.section = data.section || null;
        this.description = data.description || null;
        this.order = data.order;
    }
}
